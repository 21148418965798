import 'vite/modulepreload-polyfill'
import Alpine from 'alpinejs'

import ProductForm from '@scripts/components/product-form'

window.Alpine = Alpine

document.addEventListener('alpine:init', () => {
  // store

  // components

  // sections
  Alpine.data('productForm', ProductForm)
})

Alpine.start()
