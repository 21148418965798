/**
 * Convert an object into URL parameter format.
 *
 * @param {Object} obj - The object to convert.
 * @returns {string} The URL parameter representation of the object.
 *
 * @example
 * const urlParamsObj = {
 *     section_id: 'someId',
 *     sort_by: 'someSort',
 *     sampleArray: ['val1','val2']
 * };
 * console.log(toUrlParams(urlParamsObj));
 * // Output: section_id=someId&sort_by=someSort&sampleArray=val1&sampleArray=val2
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function toUrlParams (obj: Record<string, any>): string {
  const params: string[] = []

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      // If the property value is an array, create multiple parameters
      if (Array.isArray(obj[key])) {
        obj[key].forEach((value: string) => {
          params.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        })
      } else {
        // For non-array values, just add the key-value pair as a parameter
        params.push(
          `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`
        )
      }
    }
  }

  return params.join('&')
}

/**
 * Converts an object into a filtered URL parameter format.
 * Filters out "section_id" key.
 *
 * @param {Object} params - The object containing parameters to convert.
 * @returns {string} The filtered URL parameter representation of the object.
 *
 * @example
 * const paramsObj = {
 *     section_id: '12345',
 *     sort_by: 'date',
 *     tags: ['red', 'large']
 * };
 * console.log(toFilteredUrlParams(paramsObj));
 * // Output: sort_by=date&tags=red&tags=large
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function toFilteredUrlParams (params: Record<string, any>): string {
  const url = new URLSearchParams()

  for (const key in params) {
    if (key !== 'section_id') {
      if (Array.isArray(params[key])) {
        // If the property is an array, add each item individually
        for (const value of params[key]) {
          url.append(key, value)
        }
      } else {
        url.append(key, params[key])
      }
    }
  }

  return url.toString()
}

/**
 * Updates a DOM element with new content from a given HTML string.
 *
 * @param {string} selector - The selector of the element to update.
 * @param {string} responseHTML - The HTML string containing the updated content.
 */
export function updateDOMContent (selector: string, responseHTML: string): void {
  const parser = new DOMParser()
  const doc = parser.parseFromString(responseHTML, 'text/html')

  const newContentElement = doc.querySelector(selector)
  const newContent = newContentElement ? newContentElement.innerHTML : null

  const currentElement = document.querySelector(selector) as HTMLElement
  if (currentElement) {
    if (newContent) {
      currentElement.innerHTML = newContent
      currentElement.style.display = ''
    } else {
      currentElement.innerHTML = ''
      currentElement.style.display = 'none'
    }
  }
}

/**
 * Updates the browser's URL without reloading the page.
 *
 * @param {string} newURL - The new URL to set.
 */
export function updateURL (newURL: string): void {
  window.history.pushState({}, '', newURL)
}

export function isEmailValid (email: string): boolean {
  const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

  return pattern.test(email)
}

/**
 * Get offset of element relative to document.
 *
 * @param {element} el
 * @return {Object} The top and left offset of the element relative to the document
 */
export function getElementOffset (el: HTMLElement): { top: number; left: number } {
  let top = 0
  let left = 0
  let element = el

  // Loop through the DOM tree
  // and add it's parent's offset to get page offset
  do {
    top += element.offsetTop || 0
    left += element.offsetLeft || 0
    element = element.offsetParent as HTMLElement
  } while (element)

  return {
    top,
    left
  }
}

/**
 * Retrieve tag/s from an array with the specified prefix
 *
 * @param {Array, String, Boolean} { tags, prefix, multiple }
 * @returns Array
 */
export const getTagsFromPrefix = ({ tags, prefix, multiple = false }: { tags: string[]; prefix: string; multiple?: boolean }): string | string[] | null => {
  if (!tags) return null

  if (!multiple) {
    const tagWithPrefix = tags.find(tag => tag.includes(prefix))

    return tagWithPrefix ? tagWithPrefix.split(prefix)[1] : null
  }

  const tagsWithPrefix = tags.filter(tag => tag.includes(prefix))

  return tagsWithPrefix.map(tag => tag.split(prefix)[1])
}

/**
 * Retrieve tag/s from an array without prefix
 *
 * @param {Array, String, Boolean} { tags, prefix, multiple }
 * @returns Array
 */
export const getTagWithoutPrefix = ({ tags, value }: { tags: string[]; value: string }): string | null => {
  if (!tags) return null

  return tags.find(tag => tag === value)
}

/**
 * Sorts an array of size strings from smallest to largest, with "OS" always sorted last.
 * @param {string[]} sizes - The array of size strings to sort.
 * @return {string[]} - The sorted array of size strings.
 */
export const sortLetteredSizes = (sizes: string[]): string[] => {
  /**
   * Helper function to assign a numeric value to a size string based on its position in the size scale.
   * @param {string} size - The size string to evaluate.
   * @return {number} - The numeric value representing the size string's position in the size scale.
   */
  function sizeValue (size: string): number {
    if (size === 'OS') return Infinity // OS always goes last

    const baseSizes = { S: 0, M: 1, L: 2 }
    const xCount = (size.match(/X/g) || []).length

    if (size.endsWith('S')) {
      return baseSizes.S * 10 - xCount
    } else if (size.endsWith('L')) {
      return baseSizes.L * 10 + xCount
    } else if (size === 'M') {
      return baseSizes.M * 10
    }

    return 0 // default value if size is not recognized
  }

  return sizes.slice().sort((a, b) => sizeValue(a) - sizeValue(b))
}

export const formatMoney = (amount: number) => {
  return `${window.theme.currencySymbol}${amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
}

// todo: add types
/**
 * Mutate Storefront's response data
 * Add as much as properties as needed
 *
 * @param {Object} node
 */
// export const transformStorefrontData = node => ({
//   available: node.availableForSale,
//   id: Number(node.id.replace('gid://shopify/Product/', '')),
//   handle: node.handle,
//   ...node.images.edges?.length && {
//     images: node.images.edges.map(({ node }) => ({
//       alt: node.altText || node.title,
//       height: node.height,
//       src: node.originalSrc,
//       width: node.width
//     }))
//   },
//   compare_at_price_max: Number(node.compareAtPriceRange.maxVariantPrice.amount * 100),
//   compare_at_price_min: Number(node.compareAtPriceRange.minVariantPrice.amount * 100),
//   ...node.metafields && node.metafields[0] !== null && {
//     metafields: node.metafields
//   },
//   ...node.options && {
//     options: node.options.map(option => option.name),
//     options_with_values: node.options.map(option => {
//       return {
//         name: option.name,
//         position: node.options.indexOf(option) + 1,
//         values: option.values
//       }
//     })
//   },
//   price_max: Number(node.priceRange.maxVariantPrice.amount * 100),
//   price_min: Number(node.priceRange.minVariantPrice.amount * 100),
//   tags: node.tags,
//   title: node.title,
//   ...node.totalInventory && {
//     totalInventory: node.totalInventory
//   },
//   type: node.productType,
//   ...node.variants && {
//     variants: node.variants.edges.map(({ node }) => {
//       return {
//         available: node.availableForSale,
//         id: Number(node.id.replace('gid://shopify/ProductVariant/', '')),
//         ...node.selectedOptions && {
//           options: node.selectedOptions.map(option => option.value),
//           option1: node.selectedOptions[0].value,
//           option2: node.selectedOptions[1]?.value || null,
//           option3: node.selectedOptions[1]?.value || null,
//           selectedOptions: node.selectedOptions
//         },
//         ...node.priceV2 && { price: Number(node.priceV2.amount * 100) || null },
//         ...node.selectedOptions && {
//         }
//       }
//     })
//   }
// })
